import { FirebaseError, initializeApp } from 'firebase/app';
import { User, getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { useState } from 'react';

const usePasswordAuth = (firebaseConfig: {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
}) => {
  const [user, setUser] = useState<User | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [email, setEmail] = useState<string | null>(null);
  const [password, setPassword] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);

  const signIn = () => {
    setIsSubmitting(true);
    if (!email || !password) {
      setError(new Error('メールアドレスとパスワードを入力してください'));
      setIsSubmitting(false);
      return;
    }
    signInWithEmailAndPassword(auth, email, password)
      .then((result) => {
        setUser(result.user);
      })
      .catch((err) => {
        if (
          err.code === 'auth/invalid-email' ||
          err.code === 'auth/wrong-password' ||
          err.code === 'auth/user-not-found'
        ) {
          setError(new Error('メールアドレスまたはパスワードが誤っています'));
        } else {
          setError(err);
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return { user, error, signIn, setEmail, setPassword, isSubmitting };
};

export default usePasswordAuth;
